import tw, { styled } from 'twin.macro';
import { Container } from './';

export const Section = tw(Container)`
  flex items-center justify-center
`;

export const TextBox = tw.article`
  w-full lg:w-article-text
`;

export const ImageHolder = tw.figure`
  flex-col lg:flex-row flex-1 hidden lg:flex items-center justify-center
`;

export const Img = styled.img(({ rounded = true }) => [
  tw`shadow-lg rounded-md max-w-about`,
  !rounded && tw`rounded-none`,
]);

export const Paragraph = tw.p`
  mt-2 mb-6 lg:mb-0
`;
