import tw, { styled, css } from 'twin.macro';
import { Link } from 'gatsby';

export const Container = tw.div`
  fixed w-full top-0 z-20 bg-white
`;

export const Nav = tw.nav`
  flex items-center justify-between flex-wrap px-6 py-header-y container mx-auto
`;

export const LogoHolder = tw(Link)`
  flex items-center flex-shrink-0 text-white mr-6
`;

export const Logo = tw.img`
  h-logo
`;

export const MenuBlock = tw.div`
  block md:hidden
`;

export const Hamburger = tw.button`
  px-3 py-2
`;

export const IconBar = styled.span(({ rotateTop, rotateBottom }) => [
  tw`block w-6 h-hamburger bg-dark my-iconbar transition-transform ease-smooth duration-300`,
  rotateTop && tw`transform rotate-45 relative -bottom-1`,
  rotateBottom && tw`transform -rotate-45 relative -top-1`,
]);

export const NavBar = styled.div(({ toggled }) => [
  tw`w-full overflow-hidden flex-grow md:flex md:items-center md:w-auto text-sm justify-end transition-mobile-menu ease-smooth duration-500`,
  !toggled && tw`max-h-0 md:max-h-initial`,
  toggled && tw`mt-4 md:mt-0 max-h-mobile-menu`,
]);

export const NavLink = tw.a`
  block mt-4 md:inline-block md:mt-0 text-dark hover:text-primary-dark text-base mr-8 last:mr-0
`;

export const NavItem = styled(Link)([
  // use tailwind and css block separately if you prefer
  css`
    ${tw`block mt-4 md:inline-block md:mt-0 text-dark hover:text-primary-dark text-base md:mr-8 last:mr-0`}
    &.active {
      ${tw`md:text-primary-darker md:font-semibold`}
    }
    @media screen and (max-width: 767px) {
      ${tw`px-3 mx-3 md:px-0`}

      &:hover {
        ${tw`bg-primary-lighter hover:text-dark rounded`}
      }
    }
  `,
]);

export const FlexNavItem = tw(NavItem)`
  inline-flex items-center
`;

export const ItemLogo = tw.img`
  w-6 ml-2
`;
