import tw from 'twin.macro';

export const HeadingPrimary = tw.h1`
  text-4xl sm:text-5xl lg:text-6xl font-bold text-center mb-8
`;

export const HeadingSecondary = tw.h2`
  text-2xl sm:text-3xl lg:text-4xl font-bold text-center lg:text-left
`;

export const HeadingTertiary = tw.h3`
  text-xl sm:text-2xl lg:text-3xl font-bold
`;
