import tw, { styled, css, theme } from 'twin.macro';
import jumbotronBg from '../assets/images/home/jumbotron-bg.jpg';

export const Section = styled.section([
  // use tailwind and css block separately if you prefer
  css`
    ${tw`relative`}
    background-image: linear-gradient(rgba(131, 165, 45, 0.75) 0%, rgba(131, 165, 45, 0.75) 100%), url(${jumbotronBg});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;

    @media screen and (min-width: ${theme`screens.md`}) {
      background-image: linear-gradient(
          100deg,
          ${theme`colors.primary.default`} 0%,
          ${theme`colors.primary.default`} 50%,
          transparent 50%
        ),
        url(${jumbotronBg});
      background-position-x: 0, 22vw;
      background-size: 100%, calc(47vw + 20rem);
    }

    @media screen and (min-width: ${theme`screens.lg`}) {
      background-image: linear-gradient(
          100deg,
          ${theme`colors.primary.default`} 0%,
          ${theme`colors.primary.default`} 50%,
          transparent 50%
        ),
        url(${jumbotronBg});
      background-position-x: 0, 39vw;
      background-size: 100%, calc(50vw + 12rem);
    }
  `,
]);

export const Content = tw.div`
  container mx-auto py-jumbotron md:py-jumbotron-desktop
`;

export const TextBox = tw.div`
  w-full md:w-1/2 flex items-center justify-center
`;

export const Heading = tw.h1`
  text-3xl sm:text-4xl lg:text-5xl uppercase text-white italic font-bold tracking-wider relative z-10
`;

export const SubHeading = tw.span`
  text-xl sm:text-2xl lg:text-3xl lowercase tracking-normal block font-normal
`;

export const Overlay = tw.div`
  absolute top-0 left-0 w-full h-full bg-black bg-opacity-25
`;
