import tw from 'twin.macro';
import { Typography } from './';

export const Background = tw.section`
  bg-primary-lighter
`;

export const Heading = tw(Typography.HeadingSecondary)`
  text-center mb-8
`;

export const Figures = tw.div`
  flex items-center justify-evenly flex-wrap
`;

export const Figure = tw.figure`
  flex flex-col items-center m-4
`;

export const Img = tw.img`
  shadow-lg w-tender-img
`;

export const Paragraph = tw.p`
  mt-2 mb-6 lg:mb-0
`;
