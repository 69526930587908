import React from 'react';
import '@glidejs/glide/dist/css/glide.core.min.css';
import { FixedBodyProvider } from './src/context/fixedBody';
import { Layout } from './src/components';

export const wrapRootElement = ({ element }) => (
  <FixedBodyProvider>{element}</FixedBodyProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);
