import { createGlobalStyle } from 'styled-components';
import HKGroteskRegularWoff from './assets/fonts/HKGrotesk-Regular.woff';
import HKGroteskRegularWoff2 from './assets/fonts/HKGrotesk-Regular.woff2';
import HKGroteskSemiBoldWoff from './assets/fonts/HKGrotesk-SemiBold.woff';
import HKGroteskSemiBoldWoff2 from './assets/fonts/HKGrotesk-SemiBold.woff2';
import HKGroteskBoldWoff from './assets/fonts/HKGrotesk-Bold.woff';
import HKGroteskBoldWoff2 from './assets/fonts/HKGrotesk-Bold.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'HK Grotesk';
    src:  url(${HKGroteskRegularWoff}) format('woff2'),
          url(${HKGroteskRegularWoff2}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'HK Grotesk';
    src:  url(${HKGroteskSemiBoldWoff}) format('woff2'),
          url(${HKGroteskSemiBoldWoff2}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'HK Grotesk';
    src:  url(${HKGroteskBoldWoff}) format('woff2'),
          url(${HKGroteskBoldWoff2}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  body {
    font-family: 'HK Grotesk';
    ${props =>
      props.isBodyFixed
        ? `
          height: 100%;
          overflow: hidden;
          &::after {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.24);
          }
        `
        : ''}
  }
`;

export default GlobalStyle;
