import React, { createContext, useState, useMemo } from 'react';

const FixedBodyContext = createContext();

export default FixedBodyContext;
export const FixedBodyConsumer = FixedBodyContext.Consumer;
export const FixedBodyProvider = ({ children }) => {
  const [isBodyFixed, setIsBodyFixed] = useState(false);

  const toggleIsBodyFixed = () => {
    setIsBodyFixed(isFixed => !isFixed);
  };

  const value = useMemo(
    () => ({
      isBodyFixed,
      toggleIsBodyFixed,
    }),
    [isBodyFixed]
  );

  return (
    <FixedBodyContext.Provider value={value}>
      {children}
    </FixedBodyContext.Provider>
  );
};
