import tw from 'twin.macro';

export const Background = tw.footer`
  bg-footer flex justify-center
`;

export const Container = tw.div`
  py-12 sm:py-16 md:py-24 px-6 flex flex-col sm:flex-row items-center sm:items-start container
`;

export const Logo = tw.img`
  h-footer-logo
`;

export const Nav = tw.nav`
  flex-1 flex justify-end my-12 sm:my-0 sm:mr-24 lg:mr-16 text-center sm:text-left
`;

export const NavList = tw.ul`
  flex text-sm lg:pt-6 flex-col lg:flex-row
`;

export const NavItem = tw.li`
  mb-4 last:mb-0 lg:mr-12 lg:last:mr-0
`;
