import React, { useState, useContext } from 'react';
import {
  Container,
  Nav,
  LogoHolder,
  Logo,
  MenuBlock,
  Hamburger,
  NavBar,
  NavItem,
  IconBar,
  FlexNavItem,
  ItemLogo,
} from './Header.style';
import FixedBodyContext from '../context/fixedBody';
import { isMobile } from '../utilities';
import logo from '../assets/images/logo.png';
import szechenyi2020 from '../assets/images/szechenyi2020.svg';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { toggleIsBodyFixed } = useContext(FixedBodyContext);

  const toggleMobileMenu = () => {
    if (isMobile()) {
      toggleIsBodyFixed();
      setIsMenuOpen(isOpened => !isOpened);
    }
  };

  return (
    <Container>
      <Nav open={isMenuOpen}>
        <LogoHolder to="/">
          <Logo src={logo} alt="Truck Trio Kft. logó fejléc" height="43" />
        </LogoHolder>
        <MenuBlock>
          <Hamburger
            onClick={toggleMobileMenu}
            aria-label="Mobil navigáció kapcsolás"
          >
            <IconBar rotateTop={isMenuOpen} />
            <IconBar rotateBottom={isMenuOpen} />
          </Hamburger>
        </MenuBlock>
        <NavBar toggled={isMenuOpen} className={isMenuOpen && 'toggled'}>
          <NavItem onClick={toggleMobileMenu} to="/" activeClassName="active">
            Főoldal
          </NavItem>
          <NavItem
            onClick={toggleMobileMenu}
            to="/rolunk/"
            activeClassName="active"
          >
            Rólunk
          </NavItem>
          <FlexNavItem
            onClick={toggleMobileMenu}
            to="/tamogatasok/"
            activeClassName="active"
          >
            Támogatások
            <ItemLogo src={szechenyi2020} alt="Széchenyi 2020 logó" />
          </FlexNavItem>
          <NavItem
            onClick={toggleMobileMenu}
            to="/kapcsolat/"
            activeClassName="active"
          >
            Kapcsolat
          </NavItem>
        </NavBar>
      </Nav>
    </Container>
  );
}

export default Header;
