import { Typography, Container } from './';
import tw from 'twin.macro';

export const Section = tw(Container)`
  flex flex-col lg:flex-row items-center justify-center
`;

export const TextBox = tw.div`
  w-full lg:w-article-text
`;

export const Heading = tw(Typography.HeadingSecondary)`
  mb-8
`;

export const SubHeading = tw.span`
  block font-normal text-xl sm:text-2xl lg:text-3xl
`;

export const Item = tw.li`
  mb-4 last:mb-0
`;

export const List = tw.ul`
  table mb-8 mx-auto lg:mb-0 lg:mx-0 max-w-full
`;

export const Name = tw.h2`
  font-bold ml-4
`;

export const Link = tw.a`
  inline-flex items-center relative pl-12 py-2 pr-4 leading-none hover:bg-primary-darker font-bold hover:text-white rounded
`;

export const Icon = tw.svg`
  absolute left-7 transform -translate-x-1/2 fill-current text-dark group-hover:text-white
`;

export const IframeHolder = tw.div`
  flex-1 flex items-center justify-center max-w-full
`;

export const Iframe = tw.iframe`
  shadow-lg rounded-md max-w-map
`;
