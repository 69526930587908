import tw, { styled } from 'twin.macro';
import { Typography } from './';

export const Heading = tw(Typography.HeadingPrimary)`
  text-3xl sm:text-5xl lg:text-6xl
`;

export const HeadingSecondary = tw.h2`
  text-center font-bold
`;

export const Logo = tw.img`
  w-development-img mx-auto mt-8
`;

export const Paragraph = styled.p(({ narrow }) => [
  tw`mb-8 last:mb-0`,
  narrow && tw`mb-4 last:mb-0`,
]);

export const HeadingTertiary = tw.h3`
  font-bold mb-2
`;

export const List = tw.ul`
  list-disc list-inside
`;

export const Link = tw.a`
  text-primary-dark text-opacity-50 block hover:underline
`;

export const PartnersSection = tw.section`
  my-12
`;

export const PartnerLogos = tw.figure`
  flex flex-col md:flex-row items-center justify-evenly
`;

export const PartnerLogo = tw.img`
  h-16 my-2
`;

export const Img = tw.img`
  shadow-lg rounded-md
`;

export const BottomLogo = tw(Logo)`
  mb-0 mt-4
`;

export const Uppercase = tw.span`
  uppercase
`;

export const HR = tw.hr`
  my-12
`;
