import React, { useContext } from 'react';
import tw, { GlobalStyles } from 'twin.macro';
import GlobalStyle from '../globalStyle';
import { Header, Footer } from './';
import { Link } from 'gatsby';
import logo from '../assets/images/logo.png';
import FixedBodyContext from '../context/fixedBody';

const Main = tw.div`
  text-dark tracking-tight text-base lg:text-lg min-h-screen flex flex-col
`;

const Content = tw.div`
  pt-header flex-1
`;

const Layout = ({ children }) => {
  const { isBodyFixed } = useContext(FixedBodyContext);

  return (
    <Main>
      <GlobalStyles />
      <GlobalStyle isBodyFixed={isBodyFixed} />
      <Header />
      <Content>{children}</Content>
      <Footer.Background>
        <Footer.Container>
          <Footer.Logo
            src={logo}
            alt="Truck Trio Kft. logó lábléc"
            height="67"
          />
          <Footer.Nav>
            <Footer.NavList>
              <Footer.NavItem>
                <Link to="/">Főoldal</Link>
              </Footer.NavItem>
              <Footer.NavItem>
                <Link to="/rolunk/">Rólunk</Link>
              </Footer.NavItem>
              <Footer.NavItem>
                <Link to="/tamogatasok/">Támogatások</Link>
              </Footer.NavItem>
              <Footer.NavItem>
                <Link to="/kapcsolat/">Kapcsolat</Link>
              </Footer.NavItem>
            </Footer.NavList>
          </Footer.Nav>
          <a
            href="https://tanusitvany.bisnode.hu/penzugyileg-megbizhato-cegek/c4247bcb-312b-4909-870c-66a59616b630"
            target="_blank"
          >
            <img
              src="https://tanusitvany.bisnode.hu/Rating/GetImage?cid=4624063&lang=hu&typ=p&bg=FFFFFF&fg=1F1F1F"
              alt="Bisnode tanusitvany"
              onContextMenu={() => false}
              title=" A Bisnode tanúsítvánnyal rendelkező cégekkel az üzletkötés kockázata alacsony. A minősítés 100 éves nemzetközi tapasztalattal rendelkező, több száz változót figyelembe vevő Bisnode minősítési rendszeren alapul. A tanúsítvány az aktuális állapotot jelzi, és naponta frissül.   "
            />{' '}
          </a>
        </Footer.Container>
      </Footer.Background>
    </Main>
  );
};

export default Layout;
