import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby';

const Button = styled(Link)(({ desktop, mobile }) => [
  tw`transition-colors duration-500 ease-in-out bg-primary-darker hover:bg-primary-dark font-bold text-white py-2 px-4 rounded`,
  desktop && tw`mt-8 hidden lg:inline-block`,
  mobile && tw`mx-auto lg:hidden`,
]);

export const BtnHolder = styled.div(({ mobileCenter }) => [
  tw`flex items-center justify-center mt-4`,
  mobileCenter && tw`mt-0 md:items-start md:justify-start`,
]);

export default Button;
